import React, { useState } from "react";

import Errors from "./Errors";
import FormStep from "./FormStep";
import Heading from "./Heading";
import Panel from "./Panel";
import PropTypes from "prop-types";
import RadioGroup from "./RadioGroup";
import Spacer from "./Spacer";
import SubmitLine from "./SubmitLine";
import Text from "./Text";
import TextInput from "./TextInput";
import { checkNumber } from "./Results";

function Form1({
  nextStep,
  name,
  setName,
  gender,
  setGender,
  salary,
  setSalary,
  bill,
  setBill,
}) {
  const [errors, setErrors] = useState([]);

  function handleSubmit() {
    const errors = [];
    if (name === "") {
      errors.push("Please add your name");
    }
    if (gender === null) {
      errors.push("Please add your gender");
    }
    if (!checkNumber(salary)) {
      errors.push("Please enter a valid salary");
    }
    if (!checkNumber(bill)) {
      errors.push("Please enter a valid bill");
    }
    if (errors.length) {
      setErrors(errors);
    } else {
      nextStep();
    }
  }

  return (
    <FormStep>
      <Panel>
        <form>
          <Heading>Step 2</Heading>
          <Spacer height={40} />
          <TextInput id="name" value={name} handleChange={setName}>
            Your name
          </TextInput>
          <Spacer height={50} />
          <RadioGroup value={gender} handleChange={setGender} />
          <Spacer height={55} />
          <TextInput
            id="salary"
            value={salary}
            handleChange={setSalary}
            inputMode="numeric"
          >
            What is your annual salary?
          </TextInput>
          <Spacer height={55} />
          <Text size="large">
            And finally, what’s the total amount of your bill including service?
          </Text>
          <TextInput
            id="name"
            value={bill}
            handleChange={setBill}
            inputMode="numeric"
          />
          <Spacer height={55} />
          <SubmitLine handleSubmit={handleSubmit} />
          {errors && <Errors errors={errors} />}
        </form>
      </Panel>
    </FormStep>
  );
}

Form1.propTypes = {
  nextStep: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  setGender: PropTypes.func.isRequired,
  salary: PropTypes.number.isRequired,
  setSalary: PropTypes.func.isRequired,
  bill: PropTypes.number.isRequired,
  setBill: PropTypes.func.isRequired,
};

export default Form1;
