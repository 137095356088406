import Button from "./Button";
import Flex from "./Flex";
import Heading from "./Heading";
import IntroBodyUK from "./IntroBodyUK";
import IntroBodyUS from "./IntroBodyUS";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "./Spacer";
import Text from "./Text";

function Intro({ nextStep, location }) {
  return (
    <Flex direction="column" alignItems="center">
      <div className="intro-image">
        <img src="/assets/images/splash.jpg" />
      </div>
      <Spacer height={20} />
      <Heading level={1} center size="large">
        Can&apos;t buy me love, but you can buy me dinner
      </Heading>
      <Spacer height={40} />
      <Button size="large" handleClick={nextStep} mxAuto>
        Split the bill calculator
      </Button>
      <Spacer height={20} />
      <Spacer height={20} />
      {location === "uk" && <IntroBodyUK />}
      {location === "us" && <IntroBodyUS />}
      <Spacer height={30} />
      <Text center size="large">
        Try it out
      </Text>
      <Spacer height={40} />
      <Button size="large" handleClick={nextStep} mxAuto>
        Split the bill calculator
      </Button>
    </Flex>
  );
}

Intro.propTypes = {
  nextStep: PropTypes.func.isRequired,
  location: PropTypes.oneOf(["uk", "us"]),
};

export default Intro;
