import Flex from "./Flex";
import Grid from "./Grid";
import MaxWidth from "./MaxWidth";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "./Spacer";
import Text from "./Text";
import UKFlag from "../images/uk.svg";
import USFlag from "../images/us.svg";

function Disclaimer({ location }) {
  return (
    <MaxWidth value={700}>
      <Text size="small">
        {location === "uk" ? (
          <>
            We’ll use the information that you give us on
            whoshouldpaythebill.com to run our calculation, but we don’t store
            this information and we won’t hold it for longer than necessary to
            show you the result. And if you’re already a Credit Karma member,
            your answers won’t be linked to your account.
          </>
        ) : (
          <>
            Disclaimer: Credit Karma will not store any data submitted as an
            input to this calculator, or use it for any other purpose or hold it
            for longer than necessary for the purposes of the calculator. Data
            submitted as an input to this calculator will in no way impact your
            Credit Karma profile or impact your credit reports. Salary data will
            not be reported to any credit bureau.
          </>
        )}
      </Text>
      <Spacer height={20} />
      <Spacer solid height={1} />
      <Spacer height={20} />

      <div className="switcher-small">
        <Flex justifyContent="flex-end" alignItems="center">
          Location
        </Flex>
        <a href="/us">
          <USFlag />
        </a>
        <a href="/uk">
          <UKFlag />
        </a>
      </div>
    </MaxWidth>
  );
}

Disclaimer.propTypes = {
  location: PropTypes.oneOf(["uk", "us"]),
};

export default Disclaimer;
