import React, { useEffect, useState } from "react";

import Disclaimer from "./Disclaimer";
import Footer from "./Footer";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Header from "./Header";
import Intro from "./Intro";
import MaxWidth from "./MaxWidth";
import Padding from "./Padding";
import PropTypes from "prop-types";
import Results from "./Results";
import Spacer from "./Spacer";

function LocationPage({ path }) {
  const location = path.replace(/\//, "");

  const [step, setStep] = useState(
    Number(window.location.search.replace("?step=", "")) || 0
  );

  const [name1, setName1] = useState("");
  const [gender1, setGender1] = useState(null);
  const [salary1, setSalary1] = useState("");

  const [name2, setName2] = useState("");
  const [gender2, setGender2] = useState(null);
  const [salary2, setSalary2] = useState("");
  const [bill, setBill] = useState("");

  function reset() {
    setName1("");
    setGender1(null);
    setSalary1(null);
    setName2("");
    setGender2(null);
    setSalary2(null);
    setBill("");
  }

  function nextStep() {
    setStep(step + 1);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setStep(0);
  }, [path]);

  return (
    <>
      <Header location={location} />
      <Padding>
        <MaxWidth value={700}>
          {step === 0 && <Intro nextStep={nextStep} location={location} />}
          {step === 1 && (
            <Form1
              nextStep={nextStep}
              name={name1}
              setName={setName1}
              gender={gender1}
              setGender={setGender1}
              salary={salary1}
              setSalary={setSalary1}
            />
          )}
          {step === 2 && (
            <Form2
              nextStep={nextStep}
              name={name2}
              setName={setName2}
              gender={gender2}
              setGender={setGender2}
              salary={salary2}
              setSalary={setSalary2}
              bill={bill}
              setBill={setBill}
            />
          )}
          {step === 3 && (
            <Results
              nextStep={nextStep}
              name1={name1}
              name2={name2}
              gender1={gender1}
              gender2={gender2}
              salary1={salary1}
              salary2={salary2}
              bill={bill}
              location={location}
              setStep={setStep}
              reset={reset}
            />
          )}
        </MaxWidth>
        <Spacer height={40} />
        <Disclaimer location={location} />
      </Padding>
      <Spacer height={[40, 50, 60, 70, 80]} />
      <Footer location={location} />
    </>
  );
}

LocationPage.propTypes = {
  path: PropTypes.string.isRequired,
};

export default LocationPage;
