import Button from "./Button";
import Logo from "../images/logo.svg";
import PropTypes from "prop-types";
import React from "react";

function Header({ location }) {
  let homeUrl;
  let homeCta;

  if (location === "uk") {
    homeUrl = "https://www.creditkarma.co.uk";
    homeCta = "Visit creditkarma.co.uk";
  }

  if (location === "us") {
    homeUrl = "https://www.creditkarma.com";
    homeCta = "Visit creditkarma.com";
  }

  return (
    <header className="header">
      <div className="header-inner">
        <a href="/">
          <Logo className="logo" />
        </a>
        {homeCta && (
          <Button
            handleClick={() => {
              window.location.href = homeUrl;
            }}
          >
            {homeCta}
          </Button>
        )}
      </div>
    </header>
  );
}

Header.propTypes = {
  location: PropTypes.oneOf(["uk", "us"]),
};

export default Header;
