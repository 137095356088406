import PropTypes from "prop-types";
import React from "react";

function MaxWidth({ value, children }) {
  return (
    <div className="max-width" style={{ "--max-width": `${value}px` }}>
      {children}
    </div>
  );
}

MaxWidth.propTypes = {
  value: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MaxWidth;
