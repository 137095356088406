import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

function Text({ size, children, center, bold, mb, italic, as }) {
  const Element = as;

  return (
    <Element
      className={classNames({
        text: true,
        [size]: true,
        center: center,
        bold: bold,
        italic: italic,
        mb,
      })}
    >
      {children}
    </Element>
  );
}

Text.defaultProps = {
  size: "default",
  as: "p",
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  as: PropTypes.oneOf(["p", "span"]),
  center: PropTypes.bool,
  bold: PropTypes.bool,
  mb: PropTypes.bool,
  italic: PropTypes.bool,
};

export default Text;
