import PropTypes from "prop-types";
import React from "react";

function TextInput({ children, id, handleChange, value, type, inputMode }) {
  return (
    <>
      {children && (
        <label className="text-label" htmlFor={id}>
          {children}
        </label>
      )}
      <input
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        value={value}
        className="text-input"
        type={type}
        id={id}
        inputMode={inputMode}
      />
    </>
  );
}

TextInput.defaultProps = {
  type: "text",
};

TextInput.propTypes = {
  children: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  inputMode: PropTypes.string.isRequired,
};

export default TextInput;
