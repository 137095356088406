import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export function Heading({ level, children, center, size }) {
  const Element = `h${level}`;
  return (
    <Element
      className={classNames({
        heading: true,
        center: center,
        [size]: true,
      })}
    >
      {children}
    </Element>
  );
}

Heading.defaultProps = {
  level: 1,
  size: "small",
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  children: PropTypes.string.isRequired,
  center: PropTypes.bool,
  size: PropTypes.oneOf(["small", "large"]),
};

export default Heading;
