import Button from "./Button";
import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";

function SubmitLine({ children, handleSubmit }) {
  return (
    <div className="submit-line">
      <Button track="wide" handleClick={handleSubmit}>
        Submit
      </Button>
      <Text size="medium">{children}</Text>
    </div>
  );
}

SubmitLine.propTypes = {
  children: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SubmitLine;
