import Flex from "./Flex";
import Grid from "./Grid";
import Header from "./Header";
import Heading from "./Heading";
import MaxWidth from "./MaxWidth";
import Padding from "./Padding";
import React from "react";
import Spacer from "./Spacer";
import UKFlag from "../images/uk.svg";
import USFlag from "../images/us.svg";

function RootPage() {
  return (
    <>
      <Header location={location} />
      <Padding>
        <MaxWidth value={700}>
          <Spacer height={80} />
          <Heading level={2} center size="large">
            Split-the-bill Calculator
          </Heading>
          <Spacer height={40} />
          <Heading center>Please select your location</Heading>
          <Spacer height={40} />
          <MaxWidth value={400}>
            <Grid gridTemplateColumns="1fr 1fr" gridGap="40px">
              <a href="/us">
                <USFlag />
              </a>
              <a href="/uk">
                <UKFlag />
              </a>
            </Grid>
          </MaxWidth>
        </MaxWidth>
      </Padding>
    </>
  );
}

export default RootPage;
