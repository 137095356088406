import PropTypes from "prop-types";
import Radio from "./Radio";
import React from "react";

const MALE = "Male";
const FEMALE = "Female";
const NON_BINARY = "Non-binary";
const TRANSGENDER = "Transgender";
const INTERSEX = "Intersex";
const WITHHELD = "I prefer not to say";

const genders = [MALE, FEMALE, NON_BINARY, TRANSGENDER, INTERSEX, WITHHELD];

function RadioGroup({ value, handleChange }) {
  return (
    <div className="radio-group">
      <h4 className="radio-heading">Select your gender</h4>
      <div className="radios">
        {genders.map((gender, index) => {
          return (
            <Radio
              key={index}
              label={gender}
              checked={value === gender}
              handleClick={() => {
                if (value === gender) {
                  handleChange(null);
                } else {
                  handleChange(gender);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

RadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RadioGroup;
