import Heading from "./Heading";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "./Spacer";

function FormStep({ children }) {
  return (
    <div>
      <Spacer height={[40, 50, 60, 70, 80]} />
      <Heading level={2} center size="large">
        Split-the-bill Calculator
      </Heading>
      <Spacer height={[30, 32, 34, 36, 38]} />
      {children}
    </div>
  );
}

FormStep.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FormStep;
