import LocationPage from "./LocationPage";
import React from "react";
import RootPage from "./RootPage";
import Router from "preact-router";

function App() {
  return (
    <>
      <main className="app">
        <Router>
          <RootPage path="/" />
          <LocationPage path="/uk" key="uk" />
          <LocationPage path="/us" key="us" />
        </Router>
      </main>
    </>
  );
}

export default App;
