import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

function Grid({ children, gridTemplateColumns, gridTemplateRows, gridGap }) {
  return (
    <div
      className={classNames({
        grid: true,
      })}
      style={{
        "--grid-template-columns": gridTemplateColumns,
        "--grid-template-rows": gridTemplateRows,
        "--grid-gap": gridGap,
      }}
    >
      {children}
    </div>
  );
}

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  gridTemplateColumns: PropTypes.string,
  gridTemplateRows: PropTypes.string,
  gridGap: PropTypes.string,
};

export default Grid;
