import PropTypes from "prop-types";
import React from "react";

function Radio({ label, checked, handleClick }) {
  return (
    <label className="radio">
      {label}
      <input
        className="radio-input"
        type="checkbox"
        checked={checked ? "checked" : false}
        onChange={(event) => {
          const target = event.target;
          const value =
            target.type === "checkbox" ? target.checked : target.value;
          handleClick(value);
        }}
      />
      <span className="radio-mark" />
    </label>
  );
}

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default Radio;
