import Grid from "./Grid";
import MaxWidth from "./MaxWidth";
import Padding from "./Padding";
import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";

function UKFooterContent() {
  return (
    <>
      <Text mb size="small">
        Credit Karma UK Limited is a credit broker, not a lender. We get
        commission if you take out a product, but we&apos;re independent so we
        never rank offers based on that.
      </Text>
      <Text mb size="small">
        Credit Karma UK Limited is registered in England and Wales with company
        number 7891157, c/o Legalinx Limited, 3rd Floor, 207 Regent Street,
        London W1B 3HH. Credit Karma UK Limited is authorised and regulated by
        the Financial Conduct Authority under registration number 737743.
        Authorisation can be checked on the Financial Services Register at
        https://register.fca.org.uk/. Please note energy, internet and mobile
        phone services are not regulated by the FCA.
      </Text>
      <Text size="small">
        © 2022 Credit Karma UK Limited. All rights reserved.
      </Text>
    </>
  );
}

function USFooterContent() {
  return (
    <>
      <Text mb size="small">
        © 2007–2022 Credit Karma, LLC. Credit Karma® is a registered trademark
        of Credit Karma, LLC. All Rights Reserved. Product name, logo, brands,
        and other trademarks featured or referred to within Credit Karma are the
        property of their respective trademark holders. This site may be
        compensated through third party advertisers.
      </Text>
      <Text mb size="small">
        Credit Karma Mortgage, Inc. NMLS ID# 1588622 |{" "}
        <a href="https://www.creditkarma.com/about/mortgage-licenses">
          Licenses
        </a>{" "}
        |{" "}
        <a href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1588622">
          NMLS Consumer Access
        </a>
      </Text>
      <Text mb size="small">
        Credit Karma Offers, Inc. NMLS ID# 1628077 |{" "}
        <a href="https://www.creditkarma.com/about/loan-licenses">Licenses</a> |{" "}
        <a href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1628077">
          NMLS Consumer Access
        </a>
      </Text>
      <Text mb size="small">
        California loans arranged pursuant to Dep&apos;t of Business Oversight
        Finance Lenders License #60DBO-78868. Auto, homeowners, and renters
        insurance services offered through Credit Karma Insurance Services, LLC
        (dba Karma Insurance Services, LLC; CA resident license #0172748).
      </Text>
      <Text size="small">
        Only mortgage activity by Credit Karma Mortgage, Inc., dba Credit Karma
        is licensed by the State of New York. Credit Karma, LLC. and Credit
        Karma Offers, Inc. are not registered by the NYS Department of Financial
        Services.
      </Text>
      <Text size="small" mb>
        iPhone is a trademark of Apple Inc., registered in the U.S. and other
        countries. App Store is a service mark of Apple Inc.
      </Text>
      <Text size="small" mb>
        Android is a trademark of Google Inc.
      </Text>
      <Text size="small" mb>
        The Equifax logo is a registered trademark owned by Equifax in the
        United States and other countries.
      </Text>
      <Text size="small" mb>
        Credit Karma is committed to ensuring digital accessibility for people
        with disabilities. We are continually improving the user experience for
        everyone, and applying the relevant accessibility guidelines.
      </Text>
    </>
  );
}

function Footer({ location }) {
  return (
    <footer className="footer">
      <Padding>
        <MaxWidth value={1000}>
          {location === "uk" ? <UKFooterContent /> : <USFooterContent />}
        </MaxWidth>
      </Padding>
    </footer>
  );
}

Footer.propTypes = {
  location: PropTypes.oneOf(["uk", "us"]),
};

export default Footer;
