import PropTypes from "prop-types";
import React from "react";

function Panel({ children, outerChildren }) {
  return (
    <div className="panel">
      <div className="panel-inner">{children}</div>
      {outerChildren}
    </div>
  );
}

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  outerChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Panel;
