import PropTypes from "prop-types";
import React from "react";
import Spacer from "./Spacer";

function Errors({ errors }) {
  return (
    <div>
      <Spacer height={40} />
      {errors.map((error, index) => {
        return (
          <>
            {index !== 0 && <Spacer height={10} />}
            <p style={{ color: "red" }} key={index}>
              {error}
            </p>
          </>
        );
      })}
    </div>
  );
}

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Errors;
