import React from "react";
import Text from "./Text";

function IntroBodyUK() {
  return (
    <>
      <Text center bold mb>
        First dates can be awkward. If you can get past the joke that didn’t
        quite land, or stop worrying whether there’s something in your teeth,
        then the arrival of the bill is guaranteed to bring with it an
        uncomfortable discussion – often about money.
      </Text>
      <Text center mb>
        It’s an age-old dilemma. Should you go 50/50, is it romantic to pay for
        the whole thing, or do you just pay for what you ordered? Wait… didn’t
        they eat some of my fries?
      </Text>
      <Text center mb>
        And to make things fairer, are there other factors we should consider?
        Should the person who earns the most pick up more of the bill, for
        example? And what about those age-old gender stereotypes that still
        expect men to pay? It’s complex stuff.
      </Text>
      <Text center mb>
        To help with this, Credit Karma consulted with Professor Shireen Kanji,
        gender equality specialist at Brunel University, to produce a study
        aimed at understanding the complexities of gender when it comes to
        dating and money. In addition, we’ve launched the &quot;Split the
        Bill&quot; calculator, a tool that leverages data to help couples
        calculate what’s really fair when it comes to paying the check.
      </Text>
      <Text center mb>
        According to the study, women should actually be picking up about a
        third of the bill (or 33.9% to be precise). Every relationship is
        different though. And all couples face hurdles when it comes to talking
        about money.
      </Text>
      <Text center mb>
        To take some of the discomfort out of the unavoidable split-the-bill
        conversation, Credit Karma has created a calculator to help all couples
        split the bill in a more equitable way. We’ve made this calculator to
        demonstrate some of the challenges women face when it comes to the world
        of dating and money, as society’s understanding of the disparity between
        men and women is currently better understood than the challenges faced
        by non-heterosexual couples, however any couple can use the calculator.
      </Text>
      <Text center>
        To learn more about the study click{" "}
        <a href="https://www.creditkarma.com/about/commentary/go-dutch-or-go-broke-should-couples-be-splitting-the-bill-this-valentines-day">
          here
        </a>
      </Text>
    </>
  );
}

export default IntroBodyUK;
