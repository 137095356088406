import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

function Flex({ direction, children, alignItems, justifyContent, spaceItems }) {
  return (
    <div
      className={classNames({
        flex: true,
        left: spaceItems === "left",
        right: spaceItems === "right",
      })}
      style={{
        "--direction": direction,
        "--align-items": alignItems,
        "--justify-content": justifyContent,
      }}
    >
      {children}
    </div>
  );
}

Flex.defaultProps = {
  direction: "row",
  alignItems: "stretch",
  justifyContent: "flex-start",
};

const flexTypes = PropTypes.oneOf([
  "center",
  "flex-start",
  "flex-end",
  "stretch",
  "baseline",
  "space-between",
  "space-around",
]);

Flex.propTypes = {
  spaceItems: PropTypes.oneOf(["left", "right"]),
  direction: PropTypes.string,
  alignItems: flexTypes,
  justifyContent: flexTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Flex;
