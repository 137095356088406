import PropTypes from "prop-types";
import React from "react";

function Lozenge({ children }) {
  return <div className="lozenge">{children}</div>;
}

Lozenge.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Lozenge;
