import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

function process(name, value) {
  if (value === undefined) {
    return;
  }
  if (typeof value === "number") {
    value = [value, value, value, value, value];
  }
  const map = value.reduce((acc, curr, index) => {
    if (curr !== null) {
      return [...acc, [`--${name}-${index}`, `${curr}px`]];
    }
    return acc;
  }, []);
  return Object.fromEntries(map);
}

function Spacer({ height, solid }) {
  const style = {
    ...process("height", height),
  };

  return (
    <div style={style} className={classNames({ spacer: true, solid })}></div>
  );
}

const type = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.number),
]);

Spacer.propTypes = {
  height: type,
  solid: PropTypes.bool,
};

export default Spacer;
