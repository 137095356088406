import PropTypes from "prop-types";
import React from "react";

function Padding({ children }) {
  return <div className="padding">{children}</div>;
}

Padding.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Padding;
