import Button from "./Button";
import Flex from "./Flex";
import FormStep from "./FormStep";
import Heading from "./Heading";
import Lozenge from "./Lozenge";
import Panel from "./Panel";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "./Spacer";
import Text from "./Text";

export function makeNumber(value) {
  return Number(value.replace(/[^0-9.-]/g, ""));
}

export function checkNumber(value) {
  const num = makeNumber(value);
  if (isNaN(num) || num <= 0) {
    return false;
  } else {
    return true;
  }
}

function getMFSplit(fSalary, mSalary, bill, location) {
  let extraCosts;
  let consumptionRatio;
  if (location === "uk") {
    extraCosts = 11.9;
    consumptionRatio = 0.47;
  } else {
    extraCosts = 4.9;
    consumptionRatio = 0.42;
  }

  const salaryTotal = makeNumber(mSalary) + makeNumber(fSalary);
  const salaryRatio = makeNumber(fSalary) / salaryTotal;
  const finalRatio = (consumptionRatio / 0.5) * salaryRatio;
  let fSplit = makeNumber(bill) * finalRatio;
  let mSplit = makeNumber(bill) - fSplit;
  fSplit -= extraCosts;
  mSplit += extraCosts;
  return [fSplit.toFixed(2), mSplit.toFixed(2)];
}

function getNonMFSplit(aSalary, bSalary, bill) {
  const salaryTotal = makeNumber(bSalary) + makeNumber(aSalary);
  const salaryRatio = makeNumber(aSalary) / salaryTotal;
  let aSplit = makeNumber(bill) * salaryRatio;
  let bSplit = makeNumber(bill) - aSplit;
  return [aSplit.toFixed(2), bSplit.toFixed(2)];
}

function OutroPanel({ children }) {
  return (
    <div className="outro-panel-outer">
      <div className="outro-panel-inner">{children}</div>
    </div>
  );
}

OutroPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Results({
  name1,
  name2,
  gender1,
  gender2,
  salary1,
  salary2,
  bill,
  location,
  setStep,
  reset,
}) {
  let split1, split2, isMF;
  if (gender1 === "Female" && gender2 === "Male") {
    [split1, split2] = getMFSplit(salary1, salary2, bill, location);
    isMF = true;
  } else if (gender1 === "Male" && gender2 === "Female") {
    // Female is 2nd, so switch things round
    [split2, split1] = getMFSplit(salary2, salary1, bill, location);
    isMF = true;
  } else {
    [split1, split2] = getNonMFSplit(salary1, salary2, bill);
    isMF = false;
  }

  const currency = location === "uk" ? "£" : "$";

  return (
    <FormStep>
      <Panel
        outerChildren={
          <OutroPanel>
            <img className="outro-image" src="/assets/images/outro.png" />
          </OutroPanel>
        }
      >
        <Flex direction="column" alignItems="center">
          <Heading center>The results are in!</Heading>
          <Spacer height={30} />
          <Text size="medium" center>
            The results are in! Get your wallet out {name1}, you’d need to pay
          </Text>
          <Spacer height={30} />
          <Lozenge>
            {currency}
            {split1}
          </Lozenge>
          <Spacer height={30} />
          <Text size="medium" center>
            {name2} you’re covering the remaining
          </Text>
          <Spacer height={30} />
          <Lozenge>
            {currency}
            {split2}
          </Lozenge>
          <Spacer height={40} />
          {!isMF && (
            <>
              <Text>
                As a society we’re getting better, but a lot more needs to be
                done to fully understand the implications of financial
                inequality for LGBTQ+ people. While we’ve focused this study on
                the gender pay gap between men and women, there are a number of
                circumstances that can affect your finances, from race to age,
                or even place of birth, which we can’t model just yet. For
                anyone struggling to manage their money, our website offers
                tips, or there are a number of the independent debt charities
                you can visit.{" "}
              </Text>
              <Spacer height={40} />
            </>
          )}
          <Button
            handleClick={() => {
              reset();
              setStep(1);
            }}
          >
            Recalculate?
          </Button>
        </Flex>
      </Panel>
      <OutroPanel />
    </FormStep>
  );
}

Results.propTypes = {
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  gender1: PropTypes.string.isRequired,
  gender2: PropTypes.string.isRequired,
  salary1: PropTypes.number.isRequired,
  salary2: PropTypes.number.isRequired,
  bill: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  location: PropTypes.oneOf(["uk", "us"]),
};

export default Results;
