import "./index.scss";

import App from "./components/App";
import React from "react";
import ReactDOM from "react-dom";

// UK: https://docs.google.com/document/d/1750p8eTWdtAg2NlwSRzuCCZHH6c18Ua6kZNqCgKHoos/edit
// US: https://docs.google.com/document/d/1PaodUjHEkfMNlryilnQpsSPZo5h9DIrRuGA_Vb_Qjl4/edit

// https://docs.google.com/document/d/1IjCrxXPfBS1JEUluL7_SUwDA8xe-vMXJVL7h_P3k63c/edit

export const isDev = process.env.NODE_ENV === "development";
console.log("Bill splitter 010");

const rootEl = document.getElementById("app-root");

ReactDOM.render(<App />, rootEl);
