import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

function Button({ size, handleClick, track, children }) {
  return (
    <button
      className={classNames({
        button: true,
        [size]: true,
        wide: track === "wide",
      })}
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  size: "small",
  track: "normal",
};

Button.propTypes = {
  size: PropTypes.oneOf(["small", "large"]),
  handleClick: PropTypes.func.isRequired,
  track: PropTypes.oneOf(["wide", "normal"]),
  children: PropTypes.string.isRequired,
};

export default Button;
